<template>
<div class="page area">    
    <full-page ref="fullpage" :options="options">
        <div class="section fade-in focus-map-section" >
            <h2 v-html='translate[language].introText' class=" animation fade-in delay-1 area-title"></h2>
            <div class="focus-map-container">
                <div  class="focus-map">
                    <img src="/images/city-1.png" class=" animation fade-in delay-2 area-city">
                </div>
                <div  class="focus-map">
                    <img src="/images/city-2.png" class=" animation fade-in delay-3 area-city">
                </div>
                <div  class="focus-map">
                    <img src="/images/city-3.png" class=" animation fade-in delay-4 area-city">
                </div>
                <div  class="focus-map">
                    <img src="/images/city-4.png" class=" animation fade-in delay-4 area-city">
                </div>
                <div  class="focus-map">
                    <img src="/images/city-5.png" class=" animation fade-in delay-3 area-city">
                </div>
                <div class="focus-map">
                    <img src="/images/city-6.png" class=" animation fade-in delay-2 area-city">
                </div>
            </div>
            <div class=" col-md-12 center" style="padding:20px">
                <button class="area-btn-gallery fade-in animation delay-5" @click="$refs.fullpage.api.moveSectionDown()" >
                    <img src="/images/scroll-icon.png" >
                </button>                    
            </div>
        </div>
        <div class="section fade-in " style="padding:20px">
            <h2 v-html='translate[language].mapsText' class="area-title"></h2>
            <div class="showcase-project-container" >
                <h1 class="allcaps center bold-gold animation fade-in delay-1"></h1>
                <div class="showcase-projects animation fade-in delay-2">
                    <div class="showcase-project-map animation fade-in delay-3">
                        <img src="/images/map-merida.png"> 
                        <h2 v-html="translate[language].maps.map1"></h2>
                    </div>
                    <div class="showcase-project-map animation fade-in delay-4">
                        <img src="/images/map-talum.png"> 
                        <h2 v-html="translate[language].maps.map2"></h2>
                    </div>
                    <div class="showcase-project-map animation fade-in delay-5">
                        <img src="/images/map-valle.png"> 
                        <h2 v-html="translate[language].maps.map3"></h2>
                    </div>
                    <div class="showcase-project-map animation fade-in delay-6">
                        <img src="/images/map-queretaro.png"> 
                        <h2 v-html="translate[language].maps.map4"></h2>
                    </div>
                    <div class="showcase-project-map animation fade-in delay-7">
                        <img src="/images/map-campache.png"> 
                        <h2 v-html="translate[language].maps.map5"></h2>
                    </div>
                    <div class="showcase-project-map animation fade-in delay-8">
                        <img src="/images/map-baja.png"> 
                        <h2 v-html="translate[language].maps.map6"></h2>
                    </div>
                    <div class="showcase-project-map animation fade-in delay-9">
                        <img src="/images/map-cdmx.png"> 
                        <h2 v-html="translate[language].maps.map7"></h2>
                    </div>
                </div>
                <div class="flex">
                     <button class="area-btn-gallery fade-in animation delay-10" @click="$refs.fullpage.api.moveSectionUp()">
                             <img src="/images/scroll-icon.png" class='inverted' alt="inverted">
                    </button>
                </div>
            </div>
        </div>
    </full-page>
</div>
</template>

<script>
export default {
    data(){
        return {   
            language : ( this.$cookies.get('language') == null  ? 'english'  : this.$cookies.get('language') ),
            options: {
                // afterLoad: this.afterLoad,
                scrollBar: false,
                responsiveSlides: true,
                easingcss3 : 'cubic-bezier(0.455, 0.310, 0.120, 1.020)',
                licenseKey: '',
                scrollingSpeed : 1000,
                fitToSectionDelay: 2000,
                onLeave: this.onLeave,
                css3: true,            
            },
            translate : {
                spanish: {
                    mapsText : 'LAS SIGUIENTES AREAS EN MÉXICO OFRECEN OPORTUNIDADES PARA EXPLORAR  DESARROLLOS INMOBILIARIOS EN LOS SECTORES COMERCIAL Y RESIDENCIAL PARA VENTA Y/O RENTA',
                    introText : 'LOS MEXICANOS ANHELAN COMPRAR SUS CASAS',
                    maps : {
                        map1 : "MÉRIDA Yucatán",
                        map2 : "TULUM Q. ROO",
                        map3 : "VALLE DE BRAVO EDOMEX",
                        map4 : "QUERÉTARO",
                        map5 : "CAMPECHE",
                        map6 : "BAJA CALIFORNIA",
                        map7 : "CDMX",
                    }            
                },
                english: {
                    mapsText : 'THE FOLLOWING AREAS IN MEXICO PROVIDE SOLID REAL ESTATE OPPORTUNITIES TO EXPLORE, INCLUDING COMMERCIAL AND RESIDENTIAL DEVELOPMENTS FOR SALE AND/OR RENT',
                    introText : 'Mexicans MAIN GOAL AND KEY <br> MOTIVATOR <br> IS to OWN their homes',
                    maps : {
                        map1 : "MERIDA Yucatan",
                        map2 : "TULUM Q. ROO",
                        map3 : "VALLE DE BRAVO EDOMEX",
                        map4 : "QUERETARO",
                        map5 : "CAMPECHE",
                        map6 : "BAJA CALIFORNIA",
                        map7 : "CDMX",
                    }
                },
                
            }
        }
    },
    methods:{
          checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
    },
    created(){
        this.checkLanguage()
    }
}
</script>
